<template lang="pug">
  div.app-payment
    div.app-payment__item.app-payment__item_date
      span.candidate-number
        full-info(:app="app")
        span {{ app.created_at | formattedDatetime }}
    div.app-payment__item.app-payment__item_appid
      check-boolean(:value="app.is_paid")
      |    {{ app.application_id }}
    div.app-payment__item.app-payment__item_name {{ app.name }}
    div.app-payment__item.app-payment__item_email {{ app.email }}
    div.app-payment__item.app-payment__item_phone {{ app.phone }}
    div.app-payment__item.app-payment__item_license {{ app.license_type }}
    div.app-payment__item.app-payment__item_product {{ app.product_name }}
    div.app-payment__item
      tooltip-course-info(:item="app")
    div.app-payment__item.app-payment__item_course-type {{ app.course_type }}
</template>

<script>
import FullInfo from './TaskAppFullInfo.vue'
import TooltipCourseInfo from '@/components/widget/TooltipCourseInfo.vue'

export default {
  props: {
    app: Object
  },

  components: {
    fullInfo: FullInfo,
    TooltipCourseInfo,
    checkBoolean: () => import("@/components/global/CheckBoolean.vue"),
    showMore: () => import('@/components/global/showMoreTooltip.vue')
  }
}
</script>

<style lang="scss">
.app-payment {
  width: 100%;
  display: table-row;

  &__item {
    background-color: #f4f7f9;
    padding: 0 16px;
    display: table-cell;
    border-bottom: 1px solid #feffff;
    height: 48px;
    vertical-align: middle;

    &_course-type {
      max-width: 150px;
    }
    &_appid {
      white-space: nowrap;
    }
  }
}
</style>

<style lang="scss" scoped>
.candidate-number {
  display: flex;
  align-items: center;
}
</style>
