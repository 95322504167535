<template lang="pug">
  span.full-info
    btn-icon(@click="toggle" color="red" transparent)
      v-icon(color="newPrimary") mdi-information-outline
    v-dialog(
      v-model="showing"
      max-width="500px"
      transition="dialog-bottom-transition"
      content-class="modal"
    ).modal
      v-card.card
        v-card-title.card__title
          span Application info
          app-button(@click.native="toggle" icon iconSmall).unselectable
            <i class="material-icons">close</i>

        v-card-text.card__text
          v-row
            v-col(cols="6")
              span.label-title Name:
              span.label-text {{ app.name }}
            v-col(cols="6")
              span.label-title Created At:
              span.label-text {{ app.created_at | formattedDatetime }}
            v-col(cols="6")
              span.label-title Email:
              span.label-text {{ app.email }}
            v-col(cols="6")
              span.label-title Application ID:
              span.label-text {{ app.application_id }}
            v-col(cols="6")
              span.label-title Phone:
              span.label-text {{ app.phone }}
            v-col(cols="6")
              span.label-title Payment status:
              check-boolean(:value="app.is_paid")
            v-col(cols="6")
              span.label-title Date of birth:
              span.label-text {{ app.date_of_birth | formattedDate}}
            v-col(cols="6")
              span.label-title Driving school:
              span.label-text
              template(v-if="app.driving_school") {{ app.driving_school }}
              template(v-else) -
            v-col(cols="6")
              span.label-title License type:
              span.label-text {{ app.license_type }}
            v-col(cols="6")
              span.label-title Product:
              span.label-text {{ app.product_name }}
            v-col(cols="6")
              span.label-title Course type:
              span.label-text {{ app.course_type || '-' }}
            v-col(cols="6")
            v-col(cols="6")
              span.label-title Courses:
              template(v-if="!app.courses.length") &nbsp;-
              div(v-for="(additilnalCourse, index) in app.courses" :key="index").label-text
                template(v-if="!additilnalCourse.date") {{ additilnalCourse.name }},&nbsp;
                template(v-if="additilnalCourse.date") {{ additilnalCourse.date | formattedDate }},&nbsp;
                | {{ additilnalCourse.city }}
            v-col(cols="6")
              span.label-title Address:
              span.label-text {{ app.address_1 }}, {{ app.address_2 }}, {{ app.address_3 }}
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'

export default {
  mixins: [showingMixin],

  props: {
    app: Object
  },


  components: {
    btnIcon: () => import('@/components/global/BtnIcon.vue'),
    checkBoolean: () => import('@/components/global/CheckBoolean.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    showMore: () => import('@/components/global/showMoreTooltip.vue'),
  }
}
</script>

<style lang="scss">
.modal {
  .card {
    &.v-card {
      background-color: $body-bg;
    }

    &__title.v-card__title {
      padding: 8px 8px 8px 16px;
      font-family: $font-title;
      font-size: 16px;
      color: $title;
      justify-content: space-between;
      box-shadow: 0 1px 0 0 #f4f7f9;
    }

    &__text.v-card__text {
      padding: 8px 8px 8px 16px;
    }
  }
}

.label-title {
  font-family: $font;
  opacity: .9;
  font-size: 13px;
  font-weight: bold;
  color: $secondary-title;
  margin-right: 3px;
}

.label-text {
  font-size: 13px;
  font-family: $font;
}
</style>

<style lang="scss" scoped>
.full-info {
  height: 24px;
  margin-right: 8px;
  display: block;
}
</style>
