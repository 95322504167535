<template lang="pug">
  div(v-if="!item.courses.length" class="tooltip-course") {{ item.course | courseTemplate }}
  div(v-else class="tooltip-course multiple")
    span.item {{ item.course | courseTemplate }}
    span.item(v-for="(course, index) in item.courses" :key="index")

      template(v-if="index < 2 && !item.course")
        | {{ course | courseTemplate }}
        template(v-if="!(index === item.courses.length - 1)") ,

      template(v-if="index < 1 && item.course")
        | {{ course | courseTemplate }}
        template(v-if="!(index === item.courses.length - 1)") ,

    template(v-if="(item.courses.length > 2) || (item.courses.length > 1 && item.course)")
      v-menu(max-width="185px" top content-class="tooltip-menu")
        template(v-slot:activator="{on}")
          div.link(v-on="on").item__show-more show more
        v-list
          v-list-item(v-if="item.course && item.courses")
            v-list-item-title {{ item.course | courseTemplate }}
          v-list-item(v-for="(courseRs, index) in item.courses" :key="index")
            v-list-item-title
              | {{ courseRs | courseTemplate }}
              template(v-if="!(index === item.courses.length - 1)") ,
</template>

<script>
import { convertToDefaultDateFormatDDD } from "@/util"

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  filters: {
    courseTemplate(value) {
      if (!value) return ''

      let courseDate = '-'

      if (value.category && !value.date) courseDate = ''

      if (value.date) courseDate = convertToDefaultDateFormatDDD(value.date) + ' /'

      let courseName = value.name || '-'
      let courseCity = value.city || '-'

      return `${courseDate} ${courseName} / ${courseCity}`
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-menu {
  max-height: 500px;
  overflow-y: scroll;
  min-width: 250px !important;

  .v-list-item {
    min-height: auto !important;
    padding: 5px 15px;

    .v-list-item__title {
      font-size: 11px;
      display: block;
      line-height: 1.3;
      color: $text-color;
      font-weight: bold;
    }
  }
}

.tooltip-course {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.multiple {
    max-width: 250px;
  }

  .item {
    font-size: 11px;
    display: block;
    line-height: 1.3;
    color: $text-color;
    font-weight: bold;

    &_nowrap {
      white-space: nowrap;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
    }

    &__show-more {
      font-size: 10px;
      color: $link-color;
    }
  }
}
</style>
